<template>
  <div class="home text-left">
    <div class="text-center">
      <h1>Prenotazioni Eventi</h1>
      <div v-if="error">
        <h3 style="color: red;">{{ error }}</h3>
      </div>
    </div>
    <b-container v-if="ok_organizer" fluid="sm" style="margin-top:30px;">
      <b><a :href="user_url" target="_blank">Pagina Eventi <b-icon icon="card-checklist"></b-icon></a></b>
      <!-- <b-form @submit="onSubmit" @reset="onReset"> -->
      <b-form @submit="onSubmit" @reset="onReset" style="margin-top:20px;">
        <b-form-group
          id="input-group-name-event"
          label="Nome evento:"
          label-for="name-event"
        >
          <b-form-input
            id="name-event"
            v-model="form.event_name"
            type="text"
            required
            placeholder="Inserisci il nome dell'evento"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-date-from" label="Data di inizio" label-for="date-from">
          <b-form-datepicker v-model="form.start" :min="getTomorrow()" :max="form.end" locale="it" placeholder="Data da"></b-form-datepicker>
        </b-form-group>

        <b-form-group id="input-group-date-to" label="Data di fine" label-for="date-to">
          <b-form-datepicker v-model="form.end" :min="form.start || getTomorrow()" locale="it" placeholder="Data a"></b-form-datepicker>
        </b-form-group>

        <b-form-group
          id="input-group-start-hour"
          label="Orario di inizio:"
          label-for="start-hour"
        >
          <b-time required id="start-hour" v-model="form.start_hour" locate="it" v-bind="labels || {}"></b-time>
        </b-form-group>

        <b-form-group
          id="input-group-end-hour"
          label="Orario di fine:"
          label-for="end-hour"
        >
          <b-time required id="end-hour" v-model="form.end_hour" locate="it" v-bind="labels || {}"></b-time>
        </b-form-group>

        <!-- <b-form-group
          id="input-group-ticket"
          label="Nome del Biglietto:"
          label-for="ticket"
        >
          <b-form-input
            id="ticket"
            v-model="form.ticket_name"
            type="text"
            required
            placeholder="Inserisci il nome del biglietto"
          ></b-form-input>
        </b-form-group> -->

        <b-form-group
          id="input-group-quantity"
          label="Numero massimo di partecipanti:"
          label-for="quantity"
        >
          <b-form-input
            id="quantity"
            v-model="form.quantity"
            type="number"
            required
            placeholder="Inserisci il numero massimo di partecipanti"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-text-area"
          label="Descrizione dell'evento:"
          label-for="textarea"
        >
          <b-form-textarea
            id="textarea"
            v-model="form.description"
            placeholder="Inserisci una descrizione dell'evento"
            rows="4"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
        <div class="col-sm-12" v-if="form.error" style="border: 1px solid red; padding :20px; border-radius: 4px; margin-bottom: 15px;">
          <span style="color:red;" class="col-sm-12 text-center">
            <span>Inserisci tutti i campi</span>
          </span>
        </div>
        <div class="col-sm-12" v-if="form.error_time" style="border: 1px solid red; padding :20px; border-radius: 4px; margin-bottom: 15px;">
          <span style="color:red;" class="col-sm-12 text-center">
            <span>L'orario di fine deve essere maggiore dell'orario di inzio</span>
          </span>
        </div>
        <div class="col-sm-12" v-if="error" style="border: 1px solid red; padding :20px; border-radius: 4px; margin-bottom: 15px;">
          <span style="color:red;" class="col-sm-12 text-center">
            <span v-html="error"></span>
          </span>
        </div>
        <div class="col-sm-12" v-if="success" style="border: 1px solid green; padding :20px; border-radius: 4px; margin-bottom: 15px;">
          <span style="color:green;" class="col-sm-12 text-center">
            <span>Evento registrato con successo</span>
          </span>
        </div>
        <b-button type="submit" variant="primary">Crea eventi</b-button>
        <b-button style="margin-left: 15px;" type="reset" variant="danger">Reset</b-button>
      </b-form>
    </b-container>
    <b-overlay :show="loading" no-wrap>
      <template v-slot:overlay style="width: 70%!important;">
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">Attendi..</p>
          <b-progress style="width: 800px;" v-if="progress" :value="progress_value" :max="porgress_max" show-progress animated></b-progress>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
// @ is an alias to /src
import EBService from '@/services/EBService'
import VOLService from '@/services/VOLService'
import moment from 'moment-timezone'
export default {
  name: 'Home',
  data() {
    return {
      loading: true,
      progress: false,
      progress_value: 1,
      porgress_max: 2,
      error: null,
      ok_organizer: false,
      token: null,
      form: {
        event_name: '',
        start: null,
        end: null,
        ticket_name: '',
        quantity: null,
        description: '',
        start_hour: '',
        end_hour: '',
        error: false,
        error_time: false
      },
      labels: {
        labelHours: 'Ora',
        labelMinutes: 'Minuti',
        labelSeconds: 'Secondi',
        labelIncrement: 'Incremento',
        labelDecrement: 'Decremento',
        labelSelected: 'Selezionato',
        labelNoTimeSelected: 'Nessun orario selezionato'
      },
      user_url: null,
      event_id: [],
      success: false
    }
  },
  async mounted() {
    if (!this.$route.query.token) {
      this.error = 'Link non valido'
      this.loading = false
      return
    }
    this.token = this.$route.query.token
    try {
      let organizer = await EBService.create_organizer(this.token)
      console.log('organizer', organizer)
      this.user_url = organizer.data.organizer.url
      this.ok_organizer = true
      try {
        let ris = await VOLService.patch_url(this.token, {user_url: organizer.data.organizer.url})
      } catch(err) {
         console.log(err)
        this.error = 'Si è verificato un errore'
        this.ok_organizer = false
      } finally {
        this.loading = false
      }
    } catch(error) {
      console.log(error)
      this.error = 'Si è verificato un errore'
      this.ok_organizer = false
      this.loading = false
      // setTimeout(() => {
      //   this.error = null
      // }, 3000)
    }
  },
  methods: {
    getTomorrow() {
      const today = new Date()
      const tomorrow = new Date(today)
      tomorrow.setDate(tomorrow.getDate() + 1)
      return tomorrow
    },
    checkForm() {
      if (this.form.event_name === '' || !this.form.event_name)
        return false
      if (!this.form.start)
        return false
      if (!this.form.end)
        return false
      if (!this.form.quantity)
        return false
      if (this.form.description === '' || !this.form.description)
        return false
      if (this.form.start_hour === '' || !this.form.start_hour)
        return false
      if (this.form.end_hour === '' || !this.form.end_hour)
        return false
      return true
    },
    async onSubmit(evt) {
      this.form.error = false
      this.form.error_time = false
      this.error = null
      this.success = false
      if(this.checkForm()) {
        this.loading = true
        evt.preventDefault()
        let dates = this.getDates(this.form.start, this.form.end)
        this.progress = true

        let occurrence_duration = null
        var timeStart = new Date("01/01/2007 " + this.form.start_hour).getHours()
        var timeEnd = new Date("01/01/2007 " + this.form.end_hour).getHours()
        if (timeEnd < timeStart) {
          this.progress = false
          this.loading = false
          this.form.error_time = true
          return 0
        }
        occurrence_duration= timeEnd - timeStart
        console.log('od: ', occurrence_duration)
        let event = {
          event_name: this.form.event_name,
          ticket_name: this.form.event_name + ' - Ticket',
          // start: this.form.start + 'T' + this.form.start_hour + 'Z',
          start: moment.tz(this.form.start + " " + this.form.start_hour, "Europe/Rome").utc().format(),
          // end: this.form.end + 'T' + this.form.end_hour + 'Z',
          end: moment.tz(this.form.end + " " + this.form.end_hour, "Europe/Rome").utc().format(),
          recursive: true,
          occurrence_duration: occurrence_duration * 60 * 60,
          quantity: parseInt(this.form.quantity),
          description: this.form.description,
          recurrence_rule: this.getRecurreceRule()
        }
        try {
          let ris = await EBService.create_recursive_event(this.token, event)
          if (ris) {
            console.log(ris)
            this.progress_value += 1
            this.success = true
            var vm = this
            setTimeout(() => {
              vm.success = false
            }, 4000)
          }
        } catch(error) {
          console.log(error)
          this.error = error.response.data.message || error.response.data.error || error.response.data || error
        } finally {
          this.progress = false
          this.loading = false
          this.onReset()
        }
      } else {
        this.form.error = true
      }
    },
    getRecurreceRule() {
      let recurrence_rule = "DTSTART:"
      recurrence_rule += moment.tz(this.form.start + " " + this.form.start_hour, "Europe/Rome").utc().format().split("-").filter(function(data){ 
          return data != "-";
      }).join("").split(":").filter(function(data){ 
          return data != ":";
      }).join("")
      
      recurrence_rule += '\nRRULE:UNTIL='
      recurrence_rule += moment.tz(this.form.end + " " + this.form.end_hour, "Europe/Rome").utc().format().split("-").filter(function(data){ 
          return data != "-";
      }).join("").split(":").filter(function(data){ 
          return data != ":";
      }).join("")

      recurrence_rule += ';FREQ=DAILY'
      console.log(recurrence_rule)
      return recurrence_rule
    },
    onReset(evt) {
      if(evt)
        evt.preventDefault()
      this.form.event_name = ''
      this.form.start = null
      this.form.end = null
      this.form.shop_id = null
      this.form.ticket_name = ''
      this.form.quantity = null
      this.form.description = ''
      this.form.start_hour = ''
      this.form.end_hour = ''
      this.ok_organizer = false
      this.$nextTick(() => {
        this.ok_organizer = true
      })
    },
    getDates(startDate, stopDate) {
      Date.prototype.addDays = function(days) {
       var dat = new Date(this.valueOf())
       dat.setDate(dat.getDate() + days);
       return dat;
      }
      var dateArray = new Array();
      var currentDate = new Date(startDate);
      while (currentDate <= new Date(stopDate)) {
        dateArray.push(this.formatDate(currentDate))
        currentDate = currentDate.addDays(1);
      }
      return dateArray;
    },
    formatDate(date) {
      var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
    }
  }
}
</script>

<style scoped>
</style>