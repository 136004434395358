import Api from '@/services/Api'
import axios from 'axios'
import config from '@/config'

export default {
  create_organizer (token) {
    return axios.create({
      baseURL: config.eb_backend_url+'v1/',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*'
      }
    }).post('/register-organizer')
  },
  update_url_organizer(token, data) {
    return axios.create({
      baseURL: config.eb_backend_url+'v1/',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).post('/update-url-organizer', data)
  },
  create_event(token, event) {
    return axios.create({
      baseURL: config.eb_backend_url+'v1/',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).post('/create-event', event)
  },
  publish_event(token,data) {
    return axios.create({
      baseURL: config.eb_backend_url+'v1/',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).post('/publish-event', data)
  },
  create_recursive_event(token, data) {
    return axios.create({
      baseURL: config.eb_backend_url+'v1/',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).post('/create-recursive-event', data)
  }
}