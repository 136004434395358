import axios from 'axios'
import config from '@/config'

export default {
  patch_url (token, url) {
    console.log('url', url)
    return axios.create({
      baseURL: config.vol_backend_url,
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*'
      }
    }).patch('/model/app/ebrite', url)
  }
}