var backend =  {
  vol: {
    local: 'http://127.0.0.1:5005/',
    development: 'https://myescapi.stayted.com/',
    staging: 'https://volstagingapi.digitalautomations.it/',
    production: 'https://api.vetrineonline.com/'
  },
  eventbrite: {
    local: 'http://127.0.0.1:5005/',
    development: 'https://ebriteapi.stayted.com/',
    staging: 'https://ebriteapistaging.digitalautomations.it/',
    production: 'https://ebriteapi.vetrineonline.com/'
  }
}
var frontend = {
  vol: {
    local: 'http://127.0.0.1:8080/',
    development: 'https://myesc.stayted.com/',
    staging: 'https://volstaging.digitalautomations.it/',
    production: 'https://www.vetrineonline.com/'
  },
  eventbrite: {
    local: 'http://127.0.0.1:8080/',
    development: 'https://ebrite.stayted.com/',
    staging: 'https://ebritestaging.digitalautomations.it/',
    production: 'https://ebrite.vetrineonline.com/'
  }
}
module.exports = {
  vol_backend_url: backend.vol.staging,
  eb_backend_url: backend.eventbrite.staging,
  vol_frontend_url: frontend.vol.staging,
  eb_frontend_url: frontend.eventbrite.staging
}